import {
  Box,
  Button,
  TextField,
  Typography,
  useTheme,
  InputAdornment,
  Divider,
  Autocomplete,
  AutocompleteValue,
  FormControl,
} from "@mui/material";
import { useState } from "react";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { useFetchBioMasses } from "../../../hooks/biomass";
import { useFetchActiveSuppliers } from "../../../hooks/suppliers";
import { useCreateBioMassProduct } from "../../../hooks/biomassproduct";
import { BioMassProductRequest } from "../../../types";
import { useForm } from "react-hook-form";
import { InputFormSelect } from "../../../components/form-components/InputFormSelect";
import InputFormText from "../../../components/form-components/InputFormText";

type CreateBioMassProductForm = Omit<BioMassProductRequest, "plantId" | "bioMassId">;

const defaultInput: CreateBioMassProductForm = {
  label: "",
  pricePerTonne: 0,
  supplierId: "",
  methanePotential: 0,
};

type CreateBioMassProductProps = {
  plantId: string;
  showCreate: (value: boolean) => void;
};

const CreateBioMassProduct = (props: CreateBioMassProductProps) => {
  const theme = useTheme();
  const [biomass, setBiomass] = useState<{ id: string; label: string } | null>();

  const methods = useForm({ defaultValues: defaultInput, mode: "onSubmit" });
  const { handleSubmit, control } = methods;

  const { mutate: createBioMassProduct } = useCreateBioMassProduct();
  const { data: suppliers, isLoading: isLoadingSuppliers } = useFetchActiveSuppliers(props.plantId);
  const { data: biomasses, isLoading: isLoadingBiomasses } = useFetchBioMasses(true);
  const getBiomassesOptions = biomasses?.map((b) => ({ id: b.id, label: b.name }));

  const onSubmit = (submittedValue: CreateBioMassProductForm) => {
    const payload: BioMassProductRequest = {
      ...submittedValue,
      bioMassId: biomass?.id as string,
      plantId: props.plantId,
    };
    createBioMassProduct(payload, {
      onSuccess: () => {
        props.showCreate(false);
      },
    });
  };

  if (isLoadingSuppliers || isLoadingBiomasses) {
    return <LoadingSpinner />;
  }
  return (
    <Box>
      <Typography variant="body2">Create new feedstock</Typography>
      <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ display: "flex", gap: 2, alignItems: "center", justifyContent: "space-between" }}>
            <FormControl fullWidth required={true} size="small">
              <Autocomplete
                size="small"
                disablePortal
                id="biomass-select"
                options={getBiomassesOptions || []}
                sx={{ minWidth: 200, pr: 2 }}
                renderInput={(params) => <TextField {...params} label="Biomass" required />}
                onChange={(
                  _: React.SyntheticEvent<Element, Event>,
                  newValue: AutocompleteValue<{ id: string; label: string }, false, false, false>,
                ) => setBiomass(newValue)}
              />
            </FormControl>
            <InputFormSelect
              name="supplierId"
              control={control}
              label="Select Supplier"
              required
              data={suppliers && suppliers.map((item) => ({ id: item.id, name: item.owner }))}></InputFormSelect>

            <InputFormText name="label" control={control} label="Label" required errorText={"Label is required"} />

            <InputFormText
              name="pricePerTonne"
              type="number"
              control={control}
              label="Price"
              required
              errorText={"Price is required"}
              inputProps={{
                endAdornment: <InputAdornment position="start">EUR</InputAdornment>,
              }}
            />

            {/*           <InputFormSelect
              name="currency"
              control={control}
              label="Select currency"
              required
              data={[{ id: "DDK", name: "DDK" }]}></InputFormSelect> */}

            <InputFormText
              name="methanePotential"
              type="number"
              control={control}
              label="Biomethane potential"
              required
              errorText={"Biomethane potential is required"}
            />

            <Button variant="text" color="secondary" onClick={() => props.showCreate(false)}>
              Cancel
            </Button>
            <Button variant="contained" type="submit">
              Add
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default CreateBioMassProduct;
