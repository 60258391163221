import { SubmitHandler, useForm } from "react-hook-form";
import { Typography, Divider, Box, Button, useTheme } from "@mui/material";
import InputFormFile from "../../components/form-components/InputFormFile";
import { useImportPos } from "../../hooks/incomingPos";

const defaultValues = {
  importPos: null,
};

type Props = {
  showCreate: (value: boolean) => void;
};

const ImportPos = ({ showCreate }: Props) => {
  const theme = useTheme();
  const methods = useForm({ defaultValues: defaultValues });
  const { handleSubmit, control } = methods;
  const { mutate: uploadPos } = useImportPos();

  const onSubmit: SubmitHandler<{ importPos: File | null }> = async (values) => {
    if (!values.importPos) {
      return;
    }

    const requestData = {
      importPos: values.importPos,
    };

    uploadPos(requestData);
  };

  return (
    <Box sx={{ maxWidth: 600, mt: 3 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <Typography variant="h6">Import Bought PoS</Typography> */}
        {/* <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} /> */}
        <Box>
          <Typography variant="body1" sx={{ pl: 2 }}>
            Select file you want to import, the file should follow the BioLens PoS format.
          </Typography>
          <Box sx={{ display: "flex", ml: 2, mt: 2 }}>
            <InputFormFile name="importPos" control={control} label="Upload" required={true} errorText="PoS is required" />
          </Box>
        </Box>
        <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
        <Box sx={{ display: "flex", justifyContent: "end", m: 2 }}>
          <Button variant="text" color="secondary" onClick={() => showCreate(false)}>
            Cancel
          </Button>
          <Button variant="contained" color="success" type="submit">
            Import
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default ImportPos;
