import { Box, Grid, Container } from "@mui/material";
import { useParams } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import DailyProductionHeader from "./components/DailyProductionHeader";
import DailyProductionSubstrateTable from "./components/DailyProductionSubstrateTable";
import { useCreateSubstrateDay } from "../../hooks/substrates";
import LoadingSpinner from "../../components/LoadingSpinner";
import ShowError from "../../components/ShowError";

const style = {
  p: 0,
};

const DailyProduction = () => {
  const { year, month, day } = useParams();
  const { user } = useAuth();
  const dateString = `${year}-${month}-${day}`;
  const { data: daylist, isLoading, isError } = useCreateSubstrateDay(user?.plantId ?? "", dateString);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <ShowError />;
  }

  return (
    <Container>
      <Box sx={style}>
        <DailyProductionHeader daylist={daylist} plantId={user?.plantId ?? ""} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {!isLoading && !!daylist?.id && <DailyProductionSubstrateTable daylistId={daylist.id} isLocked={daylist.isLocked} />}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default DailyProduction;
