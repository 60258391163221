import axios from "../services/axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BASE_URL } from "../services/base";
import { DataResponse, ImportedOutgoingPosResponse, OutgoingPosRequest, OutgoingPosResponse, SoldSubstratesResponse } from "../types";
import { toast } from "react-toastify";

export const useFetchOutgoingPos = () => {
  return useQuery({
    queryKey: ["outgoing-pos"],
    queryFn: async () => {
      const { data } = await axios.get<DataResponse<OutgoingPosResponse[]>>(`${BASE_URL}/outgoingpos`);
      return data.data;
    },
  });
};

export const useAddOutgoingDanishPos = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (values: OutgoingPosRequest) => {
      const { data } = await axios.post(`${BASE_URL}/outgoingpos`, values);
      return data.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["outgoing-pos"] });
      queryClient.invalidateQueries({ queryKey: ["bathtub"] });
      toast.success("Outgoing PoS created!");
    },
  });
};

export const useAddOutgoingSwedishPos = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (values: OutgoingPosRequest) => {
      const { data } = await axios.post(`${BASE_URL}/outgoingpos/swedish`, values);
      return data.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["outgoing-pos"] });
      queryClient.invalidateQueries({ queryKey: ["bathtub"] });
      toast.success("Outgoing PoS created from Swedish company!");
    },
  });
};

export const useFetchImportedOutgoingPos = () => {
  return useQuery({
    queryKey: ["outgoing-pos-imported"],
    queryFn: async () => {
      const { data } = await axios.get<DataResponse<ImportedOutgoingPosResponse[]>>(`${BASE_URL}/importedoutgoingpos`);
      return data.data;
    },
  });
};

export const useImportOldPos = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: { importPos: File }) => {
      const formData = new FormData();
      formData.append("File", data.importPos);
      return await axios.post(`${BASE_URL}/importedoutgoingpos/import`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["outgoing-pos-imported"] });
      toast.success("Uploaded successfully");
    },
  });
};

export const useFetchSoldSubstrates = () => {
  return useQuery({
    queryKey: ["soldSubstrates"],
    queryFn: async () => {
      const { data } = await axios.get<DataResponse<SoldSubstratesResponse[]>>(`${BASE_URL}/outgoingpos/sold-substrates`);
      return data.data;
    },
  });
};

export const useFilteredSoldSubstrates = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (values?: { from: string; to: string }) => {
      const query = values ? `?from=${encodeURIComponent(values.from)}&to=${encodeURIComponent(values.to)}` : "";
      const { data } = await axios.get<DataResponse<SoldSubstratesResponse[]>>(`${BASE_URL}/outgoingpos/sold-substrates${query}`);
      return data.data;
    },
    onSuccess(data) {
      queryClient.setQueryData(["soldSubstrates"], data);
    },
  });
};
