import { CheckCircle } from "@mui/icons-material";
import { Box, Divider, TextField, Typography, useTheme } from "@mui/material";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import { FRONTEND_NUMBER_FORMAT, NumberFormatter } from "../../utils/NumberFormatter";
import { CreatePoSRequest, MassBalanceResponse, MassBalanceRowResponse } from "../../types";

type MassBalanceRowTableProps = {
  data: MassBalanceResponse;
  updateSubsidyData: React.Dispatch<React.SetStateAction<CreatePoSRequest[]>>;
  subsidyData: CreatePoSRequest[];
  hasIncomingPos: boolean;
};

const MassBalanceRowTable = ({ data, updateSubsidyData, subsidyData, hasIncomingPos }: MassBalanceRowTableProps) => {
  const theme = useTheme();

  const updateSubsidy = (newValue: number, rowId: string) => {
    const rowsWithoutCurrent = subsidyData.filter((x) => x.massBalanceRowId !== rowId);

    if (newValue === 0) {
      updateSubsidyData(rowsWithoutCurrent);
      return;
    }

    updateSubsidyData([...rowsWithoutCurrent, { amountWithoutSubsidy: newValue, massBalanceRowId: rowId, subsidy: false }]);
  };

  const getInputValue = (rowId: string) => {
    const getSelectedInput = subsidyData.find((x) => x.massBalanceRowId == rowId);
    return getSelectedInput ? getSelectedInput.amountWithoutSubsidy : 0;
  };

  const renderSubsidyColumn = (params: GridRenderCellParams<MassBalanceRowResponse>) => {
    const rowId = params.id;
    const expectedNetProductionKWh = params.row.expectedNetProductionKWh;

    return (
      <Box width="100%" sx={{ display: "flex", alignItems: "center" }}>
        <TextField
          value={getInputValue(rowId.toString())}
          variant="standard"
          size="small"
          type="number"
          sx={{
            backgroundColor: "rgba(38, 153, 70, 0.05)",
            borderRadius: 1,
            paddingLeft: 1,
            paddingRight: 1,
            marginRight: 1,
            width: 75,
            "& .MuiInput-root": {
              fontSize: "14px",
            },
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === "Escape") {
              // TODO Unfocus
            }
          }}
          onChange={(e) => {
            const min = 0;
            const max = Math.trunc(expectedNetProductionKWh);
            let value = parseInt(e.target.value, 10);

            if (value > max) value = max;
            if (value < min || !value) value = min;
            updateSubsidy(value, rowId.toString());
          }}
        />
        <Typography
          variant="caption"
          className="hover"
          onClick={() => updateSubsidy(Math.trunc(expectedNetProductionKWh), rowId.toString())}>
          <strong> of {Math.trunc(expectedNetProductionKWh)}</strong>
        </Typography>
      </Box>
    );
  };

  return (
    <Box>
      <Divider sx={{ m: 2, bgcolor: theme.palette.primary.main }} />
      <DataGrid
        sx={{
          borderWidth: "0px",
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: "normal",
            textWrap: "wrap",
          },
        }}
        disableColumnMenu
        disableColumnFilter
        disableColumnSelector
        disableRowSelectionOnClick
        hideFooterPagination
        hideFooter
        rows={data.massBalanceRows}
        initialState={{
          columns: {
            columnVisibilityModel: {
              subsidyAmount: !hasIncomingPos,
            },
          },
        }}
        columns={[
          {
            field: "bioMassName",
            headerName: "Biomass",
            width: 150,
            editable: false,
            valueGetter(params) {
              return params.row.bioMass.name;
            },
            renderCell: (params) => <Typography variant="body2">{params.value}</Typography>,
          },
          {
            field: "sumTonnage",
            headerName: "Biomass tonnage",
            width: 100,
            editable: false,
            renderCell: (params) => <Typography variant="body2">{NumberFormatter(params.value, FRONTEND_NUMBER_FORMAT, 1)}</Typography>,
          },
          {
            field: "bioMassPotential",
            headerName: "Biomethane potential",
            width: 100,
            editable: false,
            renderCell: (params) => <Typography variant="body2">{NumberFormatter(params.value, FRONTEND_NUMBER_FORMAT, 1)}</Typography>,
          },
          {
            field: "theoreticalGrossProductionNm3",
            headerName: "Gross - m3",
            width: 100,
            editable: false,
            renderCell: (params) => <Typography variant="body2">{NumberFormatter(params.value, FRONTEND_NUMBER_FORMAT, 0)}</Typography>,
          },
          {
            field: "theoreticalGrossProductionKWh",
            headerName: "Gross - kWh",
            width: 110,
            editable: false,
            renderCell: (params) => <Typography variant="body2">{NumberFormatter(params.value, FRONTEND_NUMBER_FORMAT, 0)}</Typography>,
          },
          {
            field: "flareKWh",
            headerName: "Flare",
            width: 80,
            editable: false,
            renderCell: (params) => <Typography variant="body2">{NumberFormatter(params.value, FRONTEND_NUMBER_FORMAT, 0)}</Typography>,
          },
          {
            field: "methaneLossKWh",
            headerName: "Metane loss",
            width: 80,
            editable: false,
            renderCell: (params) => <Typography variant="body2">{NumberFormatter(params.value, FRONTEND_NUMBER_FORMAT, 0)}</Typography>,
          },
          {
            field: "expectedNetProductionKWh",
            headerName: "Expected Net - Kwh",
            width: 100,
            editable: false,
            renderCell: (params) => <Typography variant="body2">{NumberFormatter(params.value, FRONTEND_NUMBER_FORMAT, 0)}</Typography>,
          },
          {
            field: "expectedNetProductionNm3",
            headerName: "Expected Net - m3",
            width: 100,
            editable: false,
            renderCell: (params) => <Typography variant="body2">{NumberFormatter(params.value, FRONTEND_NUMBER_FORMAT, 0)}</Typography>,
          },
          ...(!hasIncomingPos
            ? [
                {
                  field: "subsidyAmount",
                  headerName: "Unsubsidized",
                  width: 170,
                  editable: false,
                  renderCell: renderSubsidyColumn,
                },
              ]
            : []),
          {
            field: "selfDeclaration",
            headerName: "Self decleration",
            width: 100,
            editable: false,
            align: "center",
            renderCell: (params) => (params.value !== "Missing" ? <CheckCircle color="success" fontSize="small" /> : ""),
          },
          {
            field: "allocation",
            headerName: "Allocation",
            width: 100,
            editable: false,
            renderCell: (params) => <Typography variant="body2">{params.value}</Typography>,
          },
          {
            field: "country",
            headerName: "Country",
            width: 80,
            editable: false,
            renderCell: (params) => <Typography variant="body2">{params.value}</Typography>,
          },
        ]}></DataGrid>
    </Box>
  );
};

export default MassBalanceRowTable;
