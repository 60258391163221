import React, { useMemo, useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Route, Routes } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "./hooks/useAuth";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { createTheme, CssBaseline, PaletteMode, ThemeProvider } from "@mui/material";
import { ColorContext } from "./context/ColorContext";
import { darkTheme } from "./components/themes/dark";
import { lightTheme } from "./components/themes/light";
import { ProtectedRoute } from "./components/ProtectedRoute";
import CertificateStorage from "./pages/storage/CertificateStorage";
import CreatePlant from "./pages/plant/Create";
import MonthlyProduction from "./pages/plant-owner/MonthlyProduction";
import IncomingPos from "./pages/incoming/IncomingPos";
import Landingpage from "./pages/Landingpage";
import Massbalance from "./pages/massbalance/Massbalance";
import MassbalanceByPlant from "./pages/massbalance/MassbalanceByPlant";
import MassbalanceCenter from "./pages/massbalance/MassbalanceCenter";
import MassbalanceDetails from "./pages/massbalance/Details";
import DailyProduction from "./pages/plant-owner/DailyProduction";
import MasterDataCenter from "./pages/masterdata/MasterDataCenter";
import Navbar from "./components/Navbar";
import Plant from "./pages/plant/Plant";
import PlantDetails from "./pages/plant/PlantDetails";
import PlantPotentials from "./pages/plant/PlantPotentials";
import PlantSubstrateData from "./pages/plant/PlantSubstrateData";
import PlantProductionData from "./pages/plant/PlantProductionData";
import PlantMenu from "./pages/plant/PlantMenu";
import SoldPos from "./pages/sold/SoldPos";
import SummaryLog from "./pages/storage/SummaryLog";
import useLocalStorage from "./hooks/useLocalStorage";
import PlantSuppliers from "./pages/plant/PlantSuppliers";
import Customer from "./pages/customer/Customer";
import MonthlySummary from "./pages/plant-owner/MonthlySummary";
import AdminPlantPotentials from "./pages/components/AdminPlantPotentials";
import PosDetails from "./pages/incoming/PosDetails";
import SoldSubstrates from "./pages/substrates/sold-substrates/SoldSubstrates";
import IncomingPosByPlant from "./pages/incoming/IncomingPosByPlant";
import AvailableSubstrates from "./pages/substrates/available-substrates/AvailableSubstrates";
import { ADMIN_ROLE } from "./services/base";
import { AxiosError } from "axios";
import Users from "./pages/users/Users";
import OrderRequests from "./pages/order/OrderRequests";
import OrderRequestDetails from "./pages/order/OrderRequestDetails";

const queryClient = new QueryClient();

const App = () => {
  const [themePreference, setThemePreference] = useLocalStorage("themePreference", "light");
  const [mode, setMode] = useState<PaletteMode>(themePreference);
  const { user } = useAuth();

  queryClient.setDefaultOptions({
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: 1,
      enabled: user ? true : false,
    },
    mutations: {
      onError: (err: unknown) => {
        let message = "Something went wrong. Please try again later.";
        if (err instanceof AxiosError) {
          message = (err as AxiosError<{ detail: string }>).response?.data?.detail || message;
        }
        toast.error(message);
      },
    },
  });

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode: PaletteMode) => (prevMode === "light" ? "dark" : "light"));
        setThemePreference((prevMode: PaletteMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    [setThemePreference],
  );

  const theme = React.useMemo(() => createTheme(mode === "light" ? lightTheme : darkTheme), [mode]);

  return (
    <QueryClientProvider client={queryClient}>
      <ColorContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline enableColorScheme />
          <ToastContainer
            position="top-right"
            autoClose={4000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme={theme.palette.mode}
          />
          <Navbar />
          <Routes>
            <Route index element={<Landingpage />} />
            <Route path={"?finalize-signup/:email"} element={<Landingpage />} />
            <Route element={<ProtectedRoute redirectPath="/" isAllowed={!!user} />}>
              <Route path="incoming" element={user?.role === ADMIN_ROLE ? <IncomingPos /> : <IncomingPosByPlant />} />
              <Route path="incoming/:id" element={<PosDetails />} />
              <Route path="massbalances" element={user?.role === ADMIN_ROLE ? <Massbalance /> : <MassbalanceByPlant />} />
              <Route path="massbalances/:id" element={<MassbalanceDetails />} />
            </Route>
            <Route element={<ProtectedRoute redirectPath="/" isAllowed={!!user && user.role == "Admin"} />}>
              <Route path="massbalances/new" element={<MassbalanceCenter />} />
              <Route path="masterdata" element={<MasterDataCenter />} />
              <Route path="plants" element={<Plant />} />
              <Route
                path="plants/:id/*"
                element={
                  <PlantMenu>
                    <Routes>
                      <Route path="details" element={<PlantDetails />} />
                      <Route path="potentials" element={<AdminPlantPotentials />} />
                      <Route path="substrates" element={<PlantSubstrateData />} />
                      <Route path="productions" element={<PlantProductionData />} />
                    </Routes>
                  </PlantMenu>
                }
              />
              <Route path="plants/new" element={<CreatePlant />} />
              <Route path="sold" element={<SoldPos />} />
              <Route path="customers" element={<Customer />} />
              <Route path="users" element={<Users />} />
              <Route path="storage" element={<CertificateStorage />} />
              <Route path="storage/audit" element={<SummaryLog />} />
              <Route path="sold-substrates" element={<SoldSubstrates />} />
              <Route path="substrates" element={<AvailableSubstrates />} />
              <Route path="orders" element={<OrderRequests />} />
              <Route path="orders/:id" element={<OrderRequestDetails />} />
            </Route>
            <Route element={<ProtectedRoute redirectPath="/" isAllowed={!!user && user.role == "PlantOwner"} />}>
              <Route path="daily-production" element={<MonthlyProduction />} />
              <Route path="feedstocks" element={<PlantPotentials />} />
              <Route path="plantsuppliers" element={<PlantSuppliers />} />
              <Route path="monthly-summary" element={<MonthlySummary />} />
              <Route path="daily-production/:year/:month" element={<MonthlyProduction />} />
              <Route path="daily-production/:year/:month/:day" element={<DailyProduction />} />
            </Route>
            <Route path="*" element={<p>Theres nothing here: 404!</p>} />
          </Routes>
        </ThemeProvider>
      </ColorContext.Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
