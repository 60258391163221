import { Container, Box, Typography, Divider, useTheme } from "@mui/material";
import { useFetchOrderRequestById } from "../../hooks/order";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import ShowError from "../../components/ShowError";

const OrderRequestDetails = () => {
  const { id } = useParams();
  const theme = useTheme();

  const { data, isLoading, isError } = useFetchOrderRequestById(id!);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <ShowError />;
  }

  return (
    <Container>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h4" sx={{ flexGrow: 1, color: theme.palette.primary.main }}>
          Order request
        </Typography>
      </Box>
      <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
      <Box sx={{ height: 750, maxWidth: "100%" }}>
        {data && (
          <>
            {data.customer?.name && (
              <>
                <Typography variant={"caption"}>Customer</Typography>
                <Typography gutterBottom>{data.customer?.name}</Typography>
              </>
            )}
            <Typography variant={"caption"}>Contact</Typography>
            <Typography gutterBottom>{data.contactName}</Typography>
            <Typography variant={"caption"}>Phonenumber</Typography>
            <Typography gutterBottom>{data.contactPhoneNumber}</Typography>
            <Typography variant={"caption"}>Email</Typography>
            <Typography gutterBottom>{data.contactEmail}</Typography>
            {data.contractReference && (
              <>
                <Typography variant={"caption"}>ContractReference</Typography>
                <Typography gutterBottom>{data.contractReference}</Typography>
              </>
            )}
            <Typography variant={"caption"}>CO2-eq g/MJr</Typography>
            <Typography gutterBottom>{data.gramCo2EqPerMegaJoule}</Typography>
            <Typography variant={"caption"}> Energy content(GCV), MWh</Typography>
            <Typography gutterBottom>{data.quantityKwh}</Typography>
            {data.specification && (
              <>
                <Typography variant={"caption"}>Specification</Typography>
                <Typography gutterBottom>{data.specification}</Typography>
              </>
            )}
          </>
        )}
      </Box>
    </Container>
  );
};

export default OrderRequestDetails;
