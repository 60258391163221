import axios from "../services/axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BASE_URL } from "../services/base";
import { CreatePoSRequest, DataResponse, MassBalanceResponse, PosResponse, SubstrateListResponse } from "../types";
import { toast } from "react-toastify";

export const useFetchMassbalances = () => {
  return useQuery({
    queryKey: ["massbalances"],
    queryFn: async () => {
      const { data } = await axios.get<DataResponse<MassBalanceResponse[]>>(`${BASE_URL}/massbalances`);
      return data.data;
    },
  });
};

export const useFetchMassbalancesByPlant = (plantId: string) => {
  return useQuery({
    queryKey: ["massbalances", { plant: plantId }],
    queryFn: async () => {
      const { data } = await axios.get<DataResponse<MassBalanceResponse[]>>(`${BASE_URL}/massbalances?plantId=${plantId}`);
      return data.data;
    },
  });
};

export const useFetchMassbalanceById = (id: string) => {
  return useQuery({
    queryKey: ["massbalances", id],
    queryFn: async () => {
      const { data } = await axios.get<DataResponse<MassBalanceResponse>>(`${BASE_URL}/massbalances/${id}`);
      return data.data;
    },
  });
};

export const useFetchSubstrateList = (id: string) => {
  return useQuery({
    queryKey: ["substrate", id],
    queryFn: async () => {
      const { data } = await axios.get<DataResponse<SubstrateListResponse[]>>(
        `${BASE_URL}/massbalances/${id}/substrates?limit=1000&offset=0`,
      );
      return data.data;
    },
  });
};

export const useAddIncomingPos = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (massBalanceRows: CreatePoSRequest[]) => {
      const { data } = await axios.post<DataResponse<PosResponse>>(`${BASE_URL}/massbalances/${id}/pos`, { massBalanceRows });
      return data.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["pos"] });
      toast.success("Incoming PoS created!");
    },
  });
};

export const useDeleteMassbalance = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      const { data } = await axios.delete(`${BASE_URL}/massbalances/${id}`);
      return data.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["massbalances"] });
      toast.success("Massbalance deleted successfully");
    },
  });
};
