import styled from "@emotion/styled";
import { TableCell, TableCellProps, TableRow, TableRowProps, Table, TableHead, TableBody } from "@mui/material";
import { BioMassTypeCategory, BioMassTypeCategoryType, IncomingPosDataResponse } from "../../types/index";
import { DateFormatter, FRONTEND_MONTH_YEAR_FORMAT } from "../../utils/DateFormatter";
import { NumberFormatter, FRONTEND_NUMBER_FORMAT, FRONTEND_PERCENT_FORMAT } from "../../utils/NumberFormatter";

type GHGEmissions = keyof Omit<
  IncomingPosDataResponse,
  | "feedstockBioMassTypeId"
  | "feedstockBioMassTypeName"
  | "feedstockBioMassTypeCategory"
  | "feedstockCountry"
  | "producedAt"
  | "selfDeclaration"
  | "quantityKwh"
  | "subsidy"
>;

const CustomizedTableCell = styled(TableCell, { shouldForwardProp: (prop) => prop !== "bold" })<TableCellProps & { bold?: boolean }>(({
  bold = false,
}) => {
  if (!bold) return;

  return { fontWeight: "500" };
});

const CustomizedTableRow = styled(TableRow, { shouldForwardProp: (prop) => prop !== "isLast" })<TableRowProps & { isLast?: boolean }>(({
  isLast = false,
}) => {
  if (!isLast)
    return {
      borderBottom: "1px solid rgb(224, 224, 224)",
    };

  return {
    borderBottom: "2px solid rgb(224, 224, 224)",
  };
});

interface IncomingPosRowTableProps {
  incomingPosData: IncomingPosDataResponse[];
}

const IncomingPosRowTable = ({ incomingPosData }: IncomingPosRowTableProps) => {
  const KEY_FOR_MWhGCV = "MWhGCV";
  const KEY_FOR_MWhLCV = "MWhLCV";

  let totalSumObj: { [value in GHGEmissions]: number } = {
    efs: 0,
    eec: 0,
    el: 0,
    ep: 0,
    etd: 0,
    eu: 0,
    esca: 0,
    eccs: 0,
    eccr: 0,
  };

  const showQuantitySum = (quantitySum: number): string | null | number => {
    return quantitySum === 0 ? "-" : NumberFormatter(quantitySum, FRONTEND_NUMBER_FORMAT);
  };

  const isTheLastRow = (posRow: IncomingPosDataResponse[], index: number): boolean => {
    return posRow.length === index + 1;
  };

  const getBioMassTypeCategory = (feedstockBioMassTypeCategory: string) => {
    const test = feedstockBioMassTypeCategory as BioMassTypeCategoryType;
    return BioMassTypeCategory[test];
  };

  const getGHGEmmissionReduction = (totalSumOfRow: number): string => {
    const result = 1 - totalSumOfRow / 94;
    return isNaN(result) ? "N/A" : `${NumberFormatter(result, FRONTEND_PERCENT_FORMAT)} %`;
  };

  const getTotalSumOfRow = (posRow: IncomingPosDataResponse): number =>
    posRow.efs + posRow.eec + posRow.el + posRow.ep + posRow.etd + posRow.eu + posRow.esca + posRow.eccs + posRow.eccr;

  const getTotalSum = (totalSumObj: { [value in GHGEmissions]: number }): number =>
    totalSumObj.efs +
    totalSumObj.eec +
    totalSumObj.el +
    totalSumObj.ep +
    totalSumObj.etd +
    totalSumObj.eu +
    totalSumObj.esca +
    totalSumObj.eccs +
    totalSumObj.eccr;

  const getQuantityMWhGCV = (quantityKwh: number): number => Math.trunc(quantityKwh / 1000);

  const getQuantityMWhLCV = (quantityMWhGCV: number): number => quantityMWhGCV / (12.157 / 11.057);

  const getTotalSumOfColumn = (posRow: IncomingPosDataResponse[], key: GHGEmissions) => {
    const totalSum = posRow.reduce((accumulator: number, currentValue: IncomingPosDataResponse) => {
      if (currentValue.selfDeclaration === 0 || currentValue[key] === 0) {
        return accumulator;
      }

      return accumulator + currentValue[key] * getQuantityMWhGCV(currentValue["quantityKwh"]);
    }, 0);

    totalSumObj = { ...totalSumObj, [key]: totalSum / getQuantitySum(posRow, KEY_FOR_MWhGCV) };
    const result = totalSum / getQuantitySum(posRow, KEY_FOR_MWhGCV);

    return isNaN(result) ? "N/A" : NumberFormatter(result, FRONTEND_NUMBER_FORMAT, 2);
  };

  const getQuantitySum = (posRow: IncomingPosDataResponse[], key: typeof KEY_FOR_MWhGCV | typeof KEY_FOR_MWhLCV): number => {
    const totalSum = posRow.reduce((accumulator: number, currentValue: IncomingPosDataResponse) => {
      if (currentValue.selfDeclaration === 0) {
        return accumulator;
      }

      const value =
        key === KEY_FOR_MWhGCV
          ? getQuantityMWhGCV(currentValue.quantityKwh)
          : getQuantityMWhLCV(getQuantityMWhGCV(currentValue.quantityKwh));

      return accumulator + value;
    }, 0);

    return isNaN(totalSum) ? 0 : totalSum;
  };

  return (
    <Table size="small" aria-label="pos_rows">
      <TableHead>
        <CustomizedTableRow>
          <CustomizedTableCell>Feedstock</CustomizedTableCell>
          <CustomizedTableCell>Country of origin</CustomizedTableCell>
          <CustomizedTableCell>Category</CustomizedTableCell>
          <CustomizedTableCell>Produced</CustomizedTableCell>
          <CustomizedTableCell>Efs</CustomizedTableCell>
          <CustomizedTableCell>Eec</CustomizedTableCell>
          <CustomizedTableCell>El</CustomizedTableCell>
          <CustomizedTableCell>Ep</CustomizedTableCell>
          <CustomizedTableCell>Etd</CustomizedTableCell>
          <CustomizedTableCell>Eu</CustomizedTableCell>
          <CustomizedTableCell>Esca</CustomizedTableCell>
          <CustomizedTableCell>Eccs</CustomizedTableCell>
          <CustomizedTableCell>Eccr</CustomizedTableCell>
          <CustomizedTableCell>Subsidy</CustomizedTableCell>
          <CustomizedTableCell>Total</CustomizedTableCell>
          <CustomizedTableCell>GHG Emmission Reduction</CustomizedTableCell>
          <CustomizedTableCell>Quantity (MWhLCV)</CustomizedTableCell>
          <CustomizedTableCell>Quantity (MWhGCV)</CustomizedTableCell>
        </CustomizedTableRow>
      </TableHead>
      <TableBody>
        {incomingPosData.map((posRow: IncomingPosDataResponse, index: number) => (
          <CustomizedTableRow key={`${posRow.feedstockBioMassTypeId}-${index}`} isLast={isTheLastRow(incomingPosData, index)}>
            <CustomizedTableCell component="th" scope="row">
              {posRow.feedstockBioMassTypeName}
            </CustomizedTableCell>
            <CustomizedTableCell>{posRow.feedstockCountry}</CustomizedTableCell>
            <CustomizedTableCell>{getBioMassTypeCategory(posRow.feedstockBioMassTypeCategory.toString())}</CustomizedTableCell>
            <CustomizedTableCell>{DateFormatter(posRow.producedAt, FRONTEND_MONTH_YEAR_FORMAT)}</CustomizedTableCell>
            {posRow.selfDeclaration === 0 && (
              <>
                {new Array(11).fill(0).map((_, i) => (
                  <CustomizedTableCell key={i}>N/A</CustomizedTableCell>
                ))}
                <CustomizedTableCell>
                  {NumberFormatter(getQuantityMWhLCV(getQuantityMWhGCV(posRow.quantityKwh)), FRONTEND_NUMBER_FORMAT)}
                </CustomizedTableCell>
                <CustomizedTableCell>{NumberFormatter(getQuantityMWhGCV(posRow.quantityKwh), FRONTEND_NUMBER_FORMAT)}</CustomizedTableCell>
              </>
            )}
            {posRow.selfDeclaration !== 0 && (
              <>
                <CustomizedTableCell>{NumberFormatter(posRow.efs, FRONTEND_NUMBER_FORMAT, 2)}</CustomizedTableCell>
                <CustomizedTableCell>{NumberFormatter(posRow.eec, FRONTEND_NUMBER_FORMAT, 2)}</CustomizedTableCell>
                <CustomizedTableCell>{NumberFormatter(posRow.el, FRONTEND_NUMBER_FORMAT, 2)}</CustomizedTableCell>
                <CustomizedTableCell>{NumberFormatter(posRow.ep, FRONTEND_NUMBER_FORMAT, 2)}</CustomizedTableCell>
                <CustomizedTableCell>{NumberFormatter(posRow.etd, FRONTEND_NUMBER_FORMAT, 2)}</CustomizedTableCell>
                <CustomizedTableCell>{NumberFormatter(posRow.eu, FRONTEND_NUMBER_FORMAT, 2)}</CustomizedTableCell>
                <CustomizedTableCell>{NumberFormatter(posRow.esca, FRONTEND_NUMBER_FORMAT, 2)}</CustomizedTableCell>
                <CustomizedTableCell>{NumberFormatter(posRow.eccs, FRONTEND_NUMBER_FORMAT, 2)}</CustomizedTableCell>
                <CustomizedTableCell>{NumberFormatter(posRow.eccr, FRONTEND_NUMBER_FORMAT, 2)}</CustomizedTableCell>
                <CustomizedTableCell>{posRow.subsidy ? "Yes" : "No"}</CustomizedTableCell>
                <CustomizedTableCell>{NumberFormatter(getTotalSumOfRow(posRow), FRONTEND_NUMBER_FORMAT, 2)}</CustomizedTableCell>
                <CustomizedTableCell>{getGHGEmmissionReduction(getTotalSumOfRow(posRow))}</CustomizedTableCell>
                <CustomizedTableCell>
                  {NumberFormatter(getQuantityMWhLCV(getQuantityMWhGCV(posRow.quantityKwh)), FRONTEND_NUMBER_FORMAT)}
                </CustomizedTableCell>
                <CustomizedTableCell>{NumberFormatter(getQuantityMWhGCV(posRow.quantityKwh), FRONTEND_NUMBER_FORMAT)}</CustomizedTableCell>
              </>
            )}
          </CustomizedTableRow>
        ))}
        <CustomizedTableRow>
          <CustomizedTableCell scope="row" bold={true}>
            g CO2,eq/MJ Biomethane
          </CustomizedTableCell>
          <CustomizedTableCell bold={true} colSpan={3}></CustomizedTableCell>
          <CustomizedTableCell bold={true}>{getTotalSumOfColumn(incomingPosData, "efs")}</CustomizedTableCell>
          <CustomizedTableCell bold={true}>{getTotalSumOfColumn(incomingPosData, "eec")}</CustomizedTableCell>
          <CustomizedTableCell bold={true}>{getTotalSumOfColumn(incomingPosData, "el")}</CustomizedTableCell>
          <CustomizedTableCell bold={true}>{getTotalSumOfColumn(incomingPosData, "ep")}</CustomizedTableCell>
          <CustomizedTableCell bold={true}>{getTotalSumOfColumn(incomingPosData, "etd")}</CustomizedTableCell>
          <CustomizedTableCell bold={true}>{getTotalSumOfColumn(incomingPosData, "eu")}</CustomizedTableCell>
          <CustomizedTableCell bold={true}>{getTotalSumOfColumn(incomingPosData, "esca")}</CustomizedTableCell>
          <CustomizedTableCell bold={true}>{getTotalSumOfColumn(incomingPosData, "eccs")}</CustomizedTableCell>
          <CustomizedTableCell bold={true}>{getTotalSumOfColumn(incomingPosData, "eccr")}</CustomizedTableCell>
          <CustomizedTableCell bold={true}>
            {isNaN(getTotalSum(totalSumObj)) ? "N/A" : NumberFormatter(getTotalSum(totalSumObj), FRONTEND_NUMBER_FORMAT, 2)}
          </CustomizedTableCell>
          <CustomizedTableCell bold={true}>{getGHGEmmissionReduction(getTotalSum(totalSumObj))}</CustomizedTableCell>
          <CustomizedTableCell bold={true}>{showQuantitySum(getQuantitySum(incomingPosData, KEY_FOR_MWhLCV))}</CustomizedTableCell>
          <CustomizedTableCell bold={true}>{showQuantitySum(getQuantitySum(incomingPosData, KEY_FOR_MWhGCV))}</CustomizedTableCell>
        </CustomizedTableRow>
      </TableBody>
    </Table>
  );
};

export default IncomingPosRowTable;
