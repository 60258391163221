import axios from "../services/axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BASE_URL } from "../services/base";
import { PosResponse, DataResponse, AvailableSubstratesResponse } from "../types/index";
import { toast } from "react-toastify";

export const useFetchIncomingPos = () => {
  return useQuery({
    queryKey: ["pos"],
    queryFn: async () => {
      const { data } = await axios.get<DataResponse<PosResponse[]>>(`${BASE_URL}/incomingpos`);
      return data.data;
    },
  });
};

export const useFetchIncomingPosByPlant = (plantId: string) => {
  return useQuery({
    queryKey: ["pos", { plant: plantId }],
    queryFn: async () => {
      const { data } = await axios.get<DataResponse<PosResponse[]>>(`${BASE_URL}/incomingpos/plant/${plantId}`);
      return data.data;
    },
  });
};

export const useFetchPosById = (id: string) => {
  return useQuery({
    queryKey: ["pos", id],
    queryFn: async () => {
      const { data } = await axios.get<DataResponse<PosResponse>>(`${BASE_URL}/incomingpos/${id}`);
      return data.data;
    },
  });
};

export const useAddToStorage = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: string) => {
      const requestData = { posId: id };
      const { data } = await axios.post(`${BASE_URL}/bathtub`, requestData);
      return data.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["pos"] });
      queryClient.invalidateQueries({ queryKey: ["bathtub"] });
      toast.success("Added pos to storage!");
    },
  });
};

export const useDeletePoS = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: string) => {
      const { data } = await axios.delete(`${BASE_URL}/incomingpos/${id}`);
      return data.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["pos"] });
      toast.success("Deleted PoS");
    },
  });
};

export const useImportPos = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: { importPos: File }) => {
      const formData = new FormData();
      formData.append("File", data.importPos);
      return await axios.post(`${BASE_URL}/incomingpos/import`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["pos"] });
      toast.success("Uploaded successfully");
    },
  });
};

export const useFetchAvailableSubstrates = () => {
  return useQuery({
    queryKey: ["availableSubstrates"],
    queryFn: async () => {
      const { data } = await axios.get<DataResponse<AvailableSubstratesResponse[]>>(`${BASE_URL}/incomingpos/available-substrates`);
      return data.data;
    },
  });
};

export const useFilteredAvailableSubstrates = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (values?: { from: string; to: string }) => {
      const query = values ? `?from=${encodeURIComponent(values.from)}&to=${encodeURIComponent(values.to)}` : "";
      const { data } = await axios.get<DataResponse<AvailableSubstratesResponse[]>>(`${BASE_URL}/incomingpos/available-substrates${query}`);
      return data.data;
    },
    onSuccess(data) {
      queryClient.setQueryData(["availableSubstrates"], data);
    },
  });
};
