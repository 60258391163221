import axios from "../services/axios";
import { useQuery } from "@tanstack/react-query";
import { BASE_URL } from "../services/base";
import { DataResponse, OrderResponse } from "../types";

export const useFetchOrderRequests = () => {
  return useQuery<OrderResponse[]>({
    queryKey: ["orders"],
    queryFn: async () => {
      const { data } = await axios.get<DataResponse<OrderResponse[]>>(`${BASE_URL}/order`);
      return data.data;
    },
  });
};

export const useFetchOrderRequestById = (id: string) => {
  return useQuery<OrderResponse>({
    queryKey: ["orders", "id"],
    queryFn: async () => {
      const { data } = await axios.get<DataResponse<OrderResponse>>(`${BASE_URL}/order/${id}`);
      return data.data;
    },
  });
};
