import axios from "../services/axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BASE_URL } from "../services/base";
import { BioMassRequest, BioMassResponse, DataResponse } from "../types";
import { toast } from "react-toastify";

export const useFetchBioMasses = (sorted: boolean) => {
  return useQuery({
    queryKey: ["biomasses"],
    queryFn: async () => {
      const { data } = await axios.get<DataResponse<BioMassResponse[]>>(`${BASE_URL}/biomasses`);
      if (sorted) data.data.sort((b1, b2) => b1.name.localeCompare(b2.name));
      return data.data;
    },
  });
};

export const useAddNewBioMass = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (values: BioMassRequest) => {
      const { data } = await axios.post<DataResponse<BioMassResponse[]>>(`${BASE_URL}/biomasses`, values);
      queryClient.invalidateQueries({ queryKey: ["biomasses"] });
      return data.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["biomasses"] });
      toast.success("Biomass successfully created!");
    },
  });
};

export const useUpdateBioMass = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["biomasses"],
    mutationFn: async ({ id, bioMassData }: { id: string; bioMassData: BioMassRequest }) => {
      const { data } = await axios.put<DataResponse<BioMassResponse[]>>(`${BASE_URL}/biomasses/${id}`, bioMassData);
      queryClient.invalidateQueries({ queryKey: ["biomasses"] });
      return data.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["biomasses"] });
      toast.success("Biomass successfully updated!");
    },
  });
};