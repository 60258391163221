import { Box, TextField, Typography, InputAdornment, Button, useTheme, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useUpdateSubstrateDaylist, useLockSubstrateDaylist } from "../../../hooks/substrates";
import { NumberFormatter, FRONTEND_NUMBER_FORMAT } from "../../../utils/NumberFormatter";
import { DateFormatter, FRONTEND_READABLE_DAY_WITH_YEAR } from "../../../utils/DateFormatter";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { isFirstDayOfMonth, isLastDayOfMonth } from "date-fns";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { useAuth } from "../../../hooks/useAuth";
import StarredBiomasses from "./StarredBiomasses";
import { SubstrateListDayResponse } from "../../../types/SubstrateListType";

let debounceTimer: ReturnType<typeof setTimeout>;

const debounce = (func: () => void, timeout = 300) => {
  clearTimeout(debounceTimer);
  debounceTimer = setTimeout(() => func.apply(this), timeout);
};

type DailyProductionHeaderProps = {
  daylist: SubstrateListDayResponse;
  plantId: string;
};

const DailyProductionHeader = ({ daylist, plantId }: DailyProductionHeaderProps) => {
  const { year, month, day } = useParams();
  const [input, setInput] = useState({ ...daylist });
  const theme = useTheme();
  const { mutate: updateSubstrateDayMutation } = useUpdateSubstrateDaylist(daylist, plantId);
  const { mutate: lockSubstrateDayMutation } = useLockSubstrateDaylist();
  const dateString = `${year}-${month}-${day}`;
  const date = new Date(dateString);
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (input.day !== daylist.day) {
      setInput({ ...daylist });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daylist]);

  const debounceUpdateTimeMs = 500;
  const updateInput = (key: string, value: string) => {
    const newInput = { ...input, [key]: value };
    setInput(newInput);

    debounce(() => updateSubstrateDayMutation(newInput), debounceUpdateTimeMs);
  };

  const lockDay = (confirmLockedDayId: string) => {
    lockSubstrateDayMutation(confirmLockedDayId);
  };

  const switchToNextDay = (nextDay: boolean = true) => {
    const path = location.pathname.substring(0, location.pathname.length - 2);
    const day = nextDay
      ? Number(location.pathname.substring(location.pathname.length - 2, location.pathname.length)) + 1
      : Number(location.pathname.substring(location.pathname.length - 2, location.pathname.length)) - 1;
    if (day < 10) {
      navigate(path + 0 + day);
      return;
    }
    navigate(path + day);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton aria-label="previous" disabled={isFirstDayOfMonth(date)} onClick={() => switchToNextDay(false)} color="success">
            <NavigateBefore />
          </IconButton>
        </Box>
        <Typography variant="h4" sx={{ color: theme.palette.primary.main }}>
          {DateFormatter(date, FRONTEND_READABLE_DAY_WITH_YEAR)}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
          <IconButton aria-label="next" disabled={isLastDayOfMonth(date)} onClick={() => switchToNextDay(true)} color="success">
            <NavigateNext />
          </IconButton>
        </Box>
        <Button disabled={daylist?.isLocked} onClick={() => lockDay(daylist.id)} variant="contained" color="success">
          {daylist?.isLocked ? "Locked" : "Lock day"}
        </Button>
      </Box>

      <Box sx={{ display: "flex", gap: 1 }}>
        <Box sx={{ p: 1, width: 100, m: 1 }}>
          <Typography variant="caption">
            <strong>Feedstocks</strong>
          </Typography>
          <Typography>{NumberFormatter(daylist.substratesCount, FRONTEND_NUMBER_FORMAT, 0) || "-"}</Typography>
        </Box>
        <Box sx={{ p: 1, width: 150, m: 1 }}>
          <Typography variant="caption">
            <strong>Expected gas price</strong>
          </Typography>
          <Typography>{NumberFormatter(daylist.gasPrice, FRONTEND_NUMBER_FORMAT, 3) || "-"} EUR/m3</Typography>
        </Box>
        <Box sx={{ p: 1, width: 150, m: 1 }}>
          <Typography variant="caption">
            <strong>Expected production</strong>
          </Typography>
          <Typography>{NumberFormatter(daylist.expectedProduction, FRONTEND_NUMBER_FORMAT, 1) || "-"} m3</Typography>
        </Box>
        <Box sx={{ ml: 2, p: 1, width: 180, m: 1 }}>
          <TextField
            label={<strong>Measured production</strong>}
            fullWidth
            disabled={daylist?.isLocked}
            sx={{ border: "none" }}
            type="number"
            variant="standard"
            InputProps={{
              disableUnderline: true,
              endAdornment: <InputAdornment position="start">m3</InputAdornment>,
            }}
            value={input.measuredProduction}
            onChange={(event) => updateInput("measuredProduction", event.target.value)}
          />
        </Box>
        <Box>
          <TextField
            label={<strong>Comment</strong>}
            fullWidth
            sx={{ border: "none", ml: 2, p: 1, height: 64, width: 250 }}
            variant="standard"
            multiline
            maxRows={2}
            InputProps={{
              disableUnderline: true,
            }}
            value={input.comment}
            onChange={(event) => updateInput("comment", event.target.value)}
          />
        </Box>
      </Box>
      {user && user.plantId && <StarredBiomasses plantId={user.plantId} daylist={daylist} />}
    </Box>
  );
};
export default DailyProductionHeader;
