import { useForm } from "react-hook-form";
import { Typography, Divider, Box, Button, useTheme } from "@mui/material";
import InputFormText from "../../../components/form-components/InputFormText";
import { useAddNewBioMass } from "../../../hooks/biomass";
import { InputFormSelect } from "../../../components/form-components/InputFormSelect";
import { BioMassRequest } from "../../../types";

type CreateBioMassProps = {
  showCreate: (value: boolean) => void;
};

const CreateBioMass = ({ showCreate }: CreateBioMassProps) => {
  const theme = useTheme();
  const methods = useForm<BioMassRequest>();
  const { handleSubmit, control } = methods;
  const addNewBioMass = useAddNewBioMass();

  const onSubmit = async (values: BioMassRequest) => {
    addNewBioMass.mutate(values, {
      onSuccess: () => {
        showCreate(false);
      },
    });
  };

  const categories = [
    { id: 0, name: "Manure" },
    { id: 1, name: "Biowaste" },
    { id: 2, name: "Crops" },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="body2">Create new biomethane potential</Typography>
      <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", gap: 2 }}>
          <InputFormText name="name" control={control} label="Biomass Name" required={true} errorText={"Name is required"} />
          <InputFormText
            name="defaultPotential"
            control={control}
            label="Biomethane potential"
            required={true}
            errorText={"Potential is required"}
          />
        </Box>
        <Box sx={{ maxWidth: 195 }}>
          <InputFormSelect
            name="category"
            control={control}
            label="Category"
            required
            errorText={"Category is required"}
            data={categories}
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button variant="text" color="warning" onClick={() => showCreate(false)}>
          Cancel
        </Button>
        <Button variant="contained" color="success" type="submit">
          Save
        </Button>
      </Box>
    </form>
  );
};

export default CreateBioMass;
