import axios from "../services/axios";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { BASE_URL } from "../services/base";
import {
  DataResponse,
  SubstrateMonthListResponse,
  SubstrateMonthSummaryResponse,
  SubstrateListDayResponse,
  SubstrateListResponse,
  UpdateSubstrateListDayRequest,
  SubstrateListRequest,
  UpdateSubstrateListRequest,
  BulkSubstrateRequest,
} from "../types";

export const useFetchSubstrateMonthLists = (plantId: string) => {
  return useQuery({
    queryKey: ["substrateLists", { plant: plantId }],
    queryFn: async () => {
      const { data } = await axios.get<DataResponse<SubstrateMonthListResponse[]>>(`${BASE_URL}/substratelist/months`, {
        params: {
          plantId,
          isLocked: true,
        },
      });
      return data.data;
    },
  });
};

export const useSubstrateMonthUploadData = (plantId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: { producedAt: string; substrateFile: File }) => {
      const formData = new FormData();
      formData.append("File", data.substrateFile);
      await axios.post(`${BASE_URL}/substratelist/months/upload`, formData, {
        params: {
          producedAt: data.producedAt,
          plantId: plantId,
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["substrateLists", { plant: plantId }],
      });
    },
  });
};

export const useCreateSubstrateMonth = (plantId: string, producedAt: string) => {
  return useQuery({
    queryKey: ["substrateLists", { plant: plantId, produced: producedAt }],
    queryFn: async () => {
      const { data } = await axios.post<DataResponse<SubstrateMonthListResponse>>(`${BASE_URL}/substratelist/months`, {
        plantId,
        producedAt,
      });
      return data.data;
    },
  });
};

export const useExportSubstrateMonth = () => {
  return useMutation({
    mutationFn: async (substrateMonthId: string) => {
      const url = `${BASE_URL}/substratelist/months/${substrateMonthId}/export`;
      const { data } = await axios.get(url);
      const link = document.createElement("a");
      link.href = data.data;
      link.download = `${substrateMonthId}.csv`;
      link.click();
      return true;
    },
  });
};

export const useLockSubstrateMonth = (plantId: string, producedAt: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (substrateMonthId: string) => {
      const { data } = await axios.put(`${BASE_URL}/substratelist/months/${substrateMonthId}/lock`);
      queryClient.invalidateQueries({
        queryKey: ["substrateLists", { plant: plantId, produced: producedAt }],
      });
      queryClient.invalidateQueries({
        queryKey: ["substrateDaysLists", { month: substrateMonthId }],
      });
      queryClient.invalidateQueries({
        queryKey: ["createSubstrateDaysLists", { plant: plantId, date: producedAt }],
      });
      return data.data;
    },
  });
};

export const useFetchSubstratDays = (monthId: string) => {
  return useQuery({
    queryKey: ["substrateDaysLists", { month: monthId }],
    queryFn: async () => {
      const { data } = await axios.get<DataResponse<SubstrateListDayResponse[]>>(`${BASE_URL}/substratelist/days`, {
        params: {
          monthId,
        },
      });
      return data.data;
    },
  });
};

export const useFetchSubstrates = (daylistId: string) => {
  return useQuery({
    queryKey: ["substrates", { daylist: daylistId }],
    queryFn: async () => {
      const { data } = await axios.get<DataResponse<SubstrateListResponse[]>>(`${BASE_URL}/substratelist/days/${daylistId}/substrates`);
      return data.data;
    },
  });
};

export const useFetchMonthlySummaries = (plantId: string) => {
  return useQuery({
    queryKey: ["substrates", { plant: plantId }],
    queryFn: async () => {
      const { data } = await axios.get<DataResponse<SubstrateMonthSummaryResponse[]>>(
        `${BASE_URL}/substratelist/months/summary/${plantId}`,
      );
      return data.data;
    },
  });
};

export const useFetchMonthlySummary = (monthId: string) => {
  return useQuery({
    queryKey: ["substrates", { month: monthId }],
    queryFn: async () => {
      try {
        const { data } = await axios.get<DataResponse<SubstrateMonthSummaryResponse>>(
          `${BASE_URL}/substratelist/months/${monthId}/summary`,
        );
        return data.data;
      } catch (error) {
        // Catch error and don't show it in frontend
      }
    },
  });
};

export const useCreateSubstrateDay = (plantId: string, date: string) => {
  return useQuery({
    queryKey: ["createSubstrateDaysLists", { plant: plantId, date }],
    queryFn: async () => {
      const { data } = await axios.post(`${BASE_URL}/substratelist/days`, {
        plantId,
        day: date,
      });
      return data.data;
    },
  });
};

export const useUpdateSubstrateDaylist = (daylist: SubstrateListDayResponse, plantId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (payload: UpdateSubstrateListDayRequest) => {
      const { data } = await axios.put(`${BASE_URL}/substratelist/days/${daylist.id}`, payload);
      return data.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["createSubstrateDaysLists", { plant: plantId, date: daylist.day }],
      });
      queryClient.invalidateQueries({
        queryKey: ["substrateDaysLists"],
      });
    },
  });
};

export const useLockSubstrateDaylist = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (daylistId: string) => {
      const { data } = await axios.put(`${BASE_URL}/substratelist/days/${daylistId}/lock`);
      return data.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["createSubstrateDaysLists"],
      });
      queryClient.invalidateQueries({
        queryKey: ["substrateDaysLists"],
      });
    },
  });
};

export const useUnLockSubstrateDaylist = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (daylistId: string) => {
      const { data } = await axios.put(`${BASE_URL}/substratelist/days/${daylistId}/unlock`);
      return data.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["createSubstrateDaysLists"],
      });
      queryClient.invalidateQueries({
        queryKey: ["substrateDaysLists"],
      });
    },
  });
};

export const useUnlockSubstrateMonth = (plantId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (substrateMonthId: string) => {
      const { data } = await axios.put(`${BASE_URL}/substratelist/months/${substrateMonthId}/unlock`);
      return data.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["substrateLists", { plant: plantId }],
      });
      queryClient.invalidateQueries({
        queryKey: ["substrateDaysLists"],
      });
    },
  });
};

export const useDeleteSubstrateMonthList = (plantId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (substrateListId: string) => {
      const { data } = await axios.delete(`${BASE_URL}/substratelist/months/${substrateListId}`);
      return data.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["substrateLists", { plant: plantId }],
      });
      queryClient.invalidateQueries({
        queryKey: ["substrateDaysLists"],
      });
    },
  });
};

export const useDeleteSubstrate = (daylistId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (substrateId: string) => {
      const { data } = await axios.delete(`${BASE_URL}/substratelist/substrates/${substrateId}`);
      return data.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["substrates", { daylist: daylistId }],
      });
      queryClient.invalidateQueries({
        queryKey: ["substrateDaysLists"],
      });
      queryClient.invalidateQueries({
        queryKey: ["createSubstrateDaysLists"],
      });
    },
  });
};

export const useCreateSubstrate = (daylistId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (payload: SubstrateListRequest) => {
      const { data } = await axios.post(`${BASE_URL}/substratelist/days/${daylistId}/substrates`, payload);
      return data.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["substrates", { daylist: daylistId }],
      });
      queryClient.invalidateQueries({
        queryKey: ["substrateDaysLists"],
      });
      queryClient.invalidateQueries({
        queryKey: ["createSubstrateDaysLists"],
      });
    },
  });
};

export const useUpdateSubstrate = (daylistId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (payload: UpdateSubstrateListRequest) => {
      const { id, ...substrateData } = payload;
      const { data } = await axios.put(`${BASE_URL}/substratelist/substrates/${id}`, substrateData);
      return data.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["substrates", { daylist: daylistId }],
      });
      queryClient.invalidateQueries({
        queryKey: ["substrateDaysLists"],
      });
      queryClient.invalidateQueries({
        queryKey: ["createSubstrateDaysLists"],
      });
    },
  });
};

export const useCreateBulkSubstrate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (payload: BulkSubstrateRequest) => {
      const { data } = await axios.post(`${BASE_URL}/substratelist/bulk`, payload);
      return data.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["substrates"],
      });
      queryClient.invalidateQueries({
        queryKey: ["substrateDaysLists"],
      });
      queryClient.invalidateQueries({
        queryKey: ["createSubstrateDaysLists"],
      });
    },
  });
};
