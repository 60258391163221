import { Container, Box, Typography, Divider, useTheme } from "@mui/material";
import LoadingSpinner from "../../components/LoadingSpinner";
import ShowError from "../../components/ShowError";
import { useFetchOrderRequests } from "../../hooks/order";
import { DataGrid, GridColDef, GridRowParams, GridToolbar } from "@mui/x-data-grid";
import { OrderResponse } from "../../types";
import { defaultTableStyle } from "../../components/themes/tableStyle";
import { useNavigate } from "react-router-dom";

const OrderRequests = () => {
  const theme = useTheme();
  const { data: orderRequests, isLoading, isError } = useFetchOrderRequests();
  const navigate = useNavigate();

  const columns: GridColDef<OrderResponse>[] = [
    {
      field: "name",
      headerName: "Customer",
      type: "string",
      flex: 1,
      minWidth: 200,
      editable: false,
      align: "left",
      headerAlign: "left",
      valueGetter: (params) => params.row.customer?.name,
      renderCell: (params) => <Typography variant="caption">{params.value ? params.value : "-"}</Typography>,
    },
    {
      field: "contactName",
      headerName: "Contact person",
      type: "string",
      flex: 1,
      minWidth: 200,
      editable: false,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => <Typography variant="caption">{params.value ? params.value : "-"}</Typography>,
    },
    {
      field: "contactEmail",
      headerName: "Email",
      type: "string",
      flex: 1,
      minWidth: 200,
      editable: false,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => <Typography variant="caption">{params.value ? params.value : "-"}</Typography>,
    },
    {
      field: "contactPhoneNumber",
      headerName: "Phone number",
      type: "string",
      flex: 1,
      minWidth: 200,
      editable: false,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => <Typography variant="caption">{params.value ? params.value : "-"}</Typography>,
    },
    {
      field: "contractReference",
      headerName: "Contract reference",
      type: "string",
      flex: 1,
      minWidth: 200,
      editable: false,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => <Typography variant="caption">{params.value ? params.value : "-"}</Typography>,
    },
    {
      field: "specification",
      headerName: "Specification",
      type: "string",
      flex: 1,
      minWidth: 200,
      editable: false,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => <Typography variant="caption">{params.value ? params.value : "-"}</Typography>,
    },
    {
      field: "gramCo2EqPerMegaJoule",
      headerName: "CO2-eq g/MJr",
      type: "string",
      flex: 1,
      minWidth: 200,
      editable: false,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => <Typography variant="caption">{params.value ? params.value : "-"}</Typography>,
    },
    {
      field: "quantityKwh",
      headerName: "Energy content(GCV), MWh",
      type: "string",
      flex: 1,
      minWidth: 200,
      editable: false,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => <Typography variant="caption">{params.value ? params.value : "-"}</Typography>,
    },
  ];

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <ShowError />;
  }

  return (
    <Container>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h4" sx={{ flexGrow: 1, color: theme.palette.primary.main }}>
          Order requests
        </Typography>
      </Box>
      <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
      <Box sx={{ height: 750, maxWidth: "100%" }}>
        {orderRequests && (
          <DataGrid
            columns={columns}
            rows={orderRequests}
            slots={{
              toolbar: GridToolbar,
            }}
            initialState={{ pagination: { paginationModel: { page: 0, pageSize: 25 } } }}
            density="compact"
            onRowClick={(params: GridRowParams) => navigate(`/orders/${params.row.id}`)}
            isRowSelectable={() => false}
            hideFooterSelectedRowCount
            sx={defaultTableStyle}
          />
        )}
      </Box>
    </Container>
  );
};

export default OrderRequests;
