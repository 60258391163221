import { ThemeOptions } from "@mui/material";

export const darkTheme: ThemeOptions = {
  palette: {
    mode: "dark",
    background: {
      default: "#1e1e1e",
    },
    primary: {
      main: "#70dd81",
    },
    secondary: {
      main: "#d6ca3b",
    },
    error: {
      main: "#ffb4ab",
    },
    text: {
      primary: "#f9f9f9",
      secondary: "#1e1e1e",
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          background: "#292929",
        },
      },
    },
  },
};
