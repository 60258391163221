import { useState } from "react";
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, Box, Tab, Tabs, useTheme, Divider, styled } from "@mui/material";
import { AccountCircle, KeyboardArrowDown } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { SwitchModeButton } from "./SwitchModeButton";
import { useAuth } from "../hooks/useAuth";
import { useFetchBioMasses } from "../hooks/biomass";
import { useFetchCustomers } from "../hooks/customer";
import { useFetchPlants } from "../hooks/plant";
import { CustomerResponse, PlantResponse } from "../types";

const PlantOwnerNavbar = () => {
  const theme = useTheme();
  const [[currentRootPath]] = location.pathname.matchAll(/^(\/[^/]*)/g);

  return (
    <Tabs value={currentRootPath}>
      <Tab
        label="Daily Production"
        value="/daily-production"
        to="/daily-production"
        component={Link}
        sx={{ color: theme.palette.text.primary }}
      />
      <Tab label="Feedstocks" value="/feedstocks" to="/feedstocks" component={Link} sx={{ color: theme.palette.text.primary }} />
      <Tab
        label="Suppliers List"
        value="/plantsuppliers"
        to="/plantsuppliers"
        component={Link}
        sx={{ color: theme.palette.text.primary }}
      />
      <Tab
        label="Monthly summary"
        value="/monthly-summary"
        to="/monthly-summary"
        component={Link}
        sx={{ color: theme.palette.text.primary }}
      />
      <Tab label="Massbalances" value="/massbalances" to="/massbalances" component={Link} sx={{ color: theme.palette.text.primary }} />
      <Tab label="Produced PoS" value="/incoming" to="/incoming" component={Link} sx={{ color: theme.palette.text.primary }} />
    </Tabs>
  );
};

const CustomizedMenu = styled(Menu)(
  ({ theme }) => ` 
  & .MuiMenu-paper {
    background-color: ${theme.palette.background.default};
  }

  & .MuiMenu-list a {
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 500;
  }
`,
);

type AnchorState = {
  pos: null | HTMLElement;
  substrates: null | HTMLElement;
  master: null | HTMLElement;
  login: null | HTMLElement;
};

const Navbar = () => {
  const { user, onLogout } = useAuth();
  const [anchors, setAnchors] = useState<AnchorState>({ pos: null, substrates: null, master: null, login: null });
  const [[currentRootPath]] = location.pathname.matchAll(/^(\/[^/]*)/g);
  const theme = useTheme();

  const { data: biomasses } = useFetchBioMasses(false);
  const { data: customers } = useFetchCustomers();
  const { data: plants } = useFetchPlants();

  const biomasses_alert = biomasses ? !biomasses.every((bm) => bm.defaultPotential != 0 && bm.category != "Uncategorized") : false;

  const plants_alert = plants
    ? !plants.every((p) => {
        const keys = Object.keys(p) as Array<keyof PlantResponse>;
        for (let i = 0; i < keys.length; i++) {
          const val = p[keys[i]];
          if (val == "null" || val == "unknown") {
            return false;
          }
        }
        return true;
      })
    : false;

  const customers_alert = customers
    ? !customers.every((c) => {
        const keys = Object.keys(c) as Array<keyof CustomerResponse>;
        for (let i = 0; i < keys.length; i++) {
          const val = c[keys[i]];
          if (val == "null" || val == "unknown") {
            return false;
          }
        }
        return true;
      })
    : false;

  const handleLogout = () => {
    onLogout();
    closeDropDown("login");
  };

  const closeDropDown = (key: keyof AnchorState) => {
    setAnchors({ ...anchors, [key]: null });
  };

  const openDropDown = (key: keyof AnchorState, value: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>) => {
    setAnchors({ ...anchors, [key]: value.currentTarget });
  };

  const isMasterTabActive = (currentPath: string): string => {
    switch (currentPath) {
      case "/customers":
        return "/customers";
      case "/users":
        return "/users";
      case "/orders":
        return "/orders";
      case "/masterdata":
        return "/masterdata";
      default:
        return "";
    }
  };

  return (
    <>
      <AppBar
        position="static"
        style={{ background: theme.palette.primary.main, color: theme.palette.text.secondary }}
        sx={{ background: "linear-gradient(90deg, rgba(47,112,14,1) 0%, rgba(24,142,31,1) 49%, rgba(47,112,14,1) 100%)", mb: 4 }}>
        <Toolbar>
          <Box
            component="img"
            sx={{
              width: 73,
            }}
            alt="The BioLens logo"
            src={theme.palette.mode === "dark" ? "/assets/logo-biolens-black-text.png" : "/assets/logo-biolens-white-text.png"}
          />
          <Box sx={{ flexGrow: 1 }}></Box>
          {!!user && (user.role == "Admin" || user.role == "PlantOwner") && (
            <Box style={{ display: "flex", alignItems: "center", color: theme.palette.common.white }}>
              <Typography sx={{ pr: 1 }}>{user && user.name}</Typography>
              <IconButton
                id="more-button"
                aria-label="more"
                aria-controls={anchors.login ? "action-menu" : undefined}
                aria-expanded={anchors.login ? "true" : undefined}
                onClick={(event) => openDropDown("login", event)}
                color="inherit">
                <AccountCircle color="inherit" />
              </IconButton>
              <Menu
                id="action-menu"
                anchorEl={anchors.login}
                open={Boolean(anchors.login)}
                onClose={() => closeDropDown("login")}
                MenuListProps={{
                  "aria-labelledby": "more-button",
                }}
                color="inherit">
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                <MenuItem onClick={() => closeDropDown("login")}>
                  <SwitchModeButton />
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar sx={{ display: "flex", justifyContent: "center", mb: 3 }} variant="dense">
        {!!user && user.role == "Admin" && (
          <>
            <Tabs value={currentRootPath}>
              <Tab
                label={plants_alert ? "Plants*" : "Plants"}
                value="/plants"
                to="/plants"
                component={Link}
                sx={{ color: theme.palette.text.primary }}
              />
              <Tab
                label=""
                icon={<Divider orientation="vertical" sx={{ height: 30, alignSelf: "center" }} />}
                sx={{ maxWidth: 5, minWidth: 5, p: 0, mx: 1 }}
                disabled
              />
              <Tab
                label="Massbalances"
                value="/massbalances"
                to="/massbalances"
                component={Link}
                sx={{ color: theme.palette.text.primary }}
              />
              <Tab
                label=""
                icon={<Divider orientation="vertical" sx={{ height: 30, alignSelf: "center" }} />}
                sx={{ maxWidth: 5, minWidth: 5, p: 0, mx: 1 }}
                disabled
              />
              <Tab
                id="pos-tab"
                label="PoS"
                value={location.pathname == "/incoming" ? "/incoming" : "/sold"}
                aria-controls={anchors.pos ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={anchors.pos ? "true" : undefined}
                onClick={(event) => openDropDown("pos", event)}
                sx={{ color: theme.palette.text.primary, minHeight: "unset" }}
                icon={<KeyboardArrowDown />}
                iconPosition="end"
              />
              <Tab
                label=""
                icon={<Divider orientation="vertical" sx={{ height: 30, alignSelf: "center" }} />}
                sx={{ maxWidth: 5, minWidth: 5, p: 0, mx: 1 }}
                disabled
              />
              <Tab label="Storage" value="/storage" to="/storage" component={Link} sx={{ color: theme.palette.text.primary }} />
              <Tab
                label=""
                icon={<Divider orientation="vertical" sx={{ height: 30, alignSelf: "center" }} />}
                sx={{ maxWidth: 5, minWidth: 5, p: 0, mx: 1 }}
                disabled
              />
              <Tab
                id="substrates-tab"
                label="Reporting"
                value={location.pathname == "/sold-substrates" ? "/sold-substrates" : "/substrates"}
                aria-controls={anchors.substrates ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={anchors.substrates ? "true" : undefined}
                onClick={(event) => openDropDown("substrates", event)}
                sx={{ color: theme.palette.text.primary, minHeight: "unset" }}
                icon={<KeyboardArrowDown />}
                iconPosition="end"
              />
              <Tab
                label=""
                icon={<Divider orientation="vertical" sx={{ height: 30, alignSelf: "center" }} />}
                sx={{ maxWidth: 5, minWidth: 5, p: 0, mx: 1 }}
                disabled
              />
              <Tab
                id="master-tab"
                label="Master data"
                value={isMasterTabActive(location.pathname)}
                aria-controls={anchors.master ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={anchors.master ? "true" : undefined}
                onClick={(event) => openDropDown("master", event)}
                sx={{ color: theme.palette.text.primary, minHeight: "unset" }}
                icon={<KeyboardArrowDown />}
                iconPosition="end"
              />
            </Tabs>
            <CustomizedMenu
              id="pos-tab"
              anchorEl={anchors.pos}
              open={Boolean(anchors.pos)}
              onClose={() => closeDropDown("pos")}
              MenuListProps={{
                "aria-labelledby": "pos-tab",
              }}>
              <MenuItem to="/incoming" component={Link} onClick={() => closeDropDown("pos")}>
                Produced & Bought
              </MenuItem>
              <MenuItem to="/sold" component={Link} onClick={() => closeDropDown("pos")}>
                Sold
              </MenuItem>
            </CustomizedMenu>
            <CustomizedMenu
              id="substrates-tab"
              anchorEl={anchors.substrates}
              open={Boolean(anchors.substrates)}
              onClose={() => closeDropDown("substrates")}
              MenuListProps={{
                "aria-labelledby": "substrates-tab",
              }}>
              <MenuItem to="/substrates" component={Link} onClick={() => closeDropDown("substrates")}>
                Available Substrates
              </MenuItem>
              <MenuItem to="/sold-substrates" component={Link} onClick={() => closeDropDown("substrates")}>
                Sold Substrates
              </MenuItem>
            </CustomizedMenu>
            <CustomizedMenu
              id="master-tab"
              anchorEl={anchors.master}
              open={Boolean(anchors.master)}
              onClose={() => closeDropDown("master")}
              MenuListProps={{
                "aria-labelledby": "master-tab",
              }}>
              <MenuItem to="/masterdata" component={Link} onClick={() => closeDropDown("master")}>
                {biomasses_alert ? "Biomasses*" : "Biomasses"}
              </MenuItem>
              <MenuItem to="/orders" component={Link} onClick={() => closeDropDown("master")}>
                Orders
              </MenuItem>
              <MenuItem to="/customers" component={Link} onClick={() => closeDropDown("master")}>
                {customers_alert ? "Customers*" : "Customers"}
              </MenuItem>
              <MenuItem to="/users" component={Link} onClick={() => closeDropDown("master")}>
                Users
              </MenuItem>
            </CustomizedMenu>
          </>
        )}
        {!!user && user.role == "PlantOwner" && <PlantOwnerNavbar />}
      </Toolbar>
    </>
  );
};

export default Navbar;
